import React, { useState } from 'react'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getSingleDataFromDatabase } from '../utils/api'
import moment from 'moment'
import DashboardOverview from "../components/dashboard/DashboardOverview"
import DashboardSection from "../components/dashboard/DashboardSection"
import TopProductSals from "../components/dashboard/TopProductSale"
import MostPopularLocation from "../components/dashboard/MostPopularLocation"

const Dashboard = () => {
    const navigate = useNavigate();
    const subagentid = localStorage.getItem('subagentdata');
    const [subagent, setSubagent] = useState([]);

    useEffect(() => {
        if (subagentid) {
            navigate('/dashboard');
            getDashboardSingleEmpData(subagentid);
        }
        else {
            navigate("/");
        }
    }, [subagentid]);

    const getDashboardSingleEmpData = (subagentid) => {
        getSingleDataFromDatabase(`sub-agent-get-single-data/${subagentid}`).then((res) => {
            if (res.data.status === 1) {
                setSubagent(...[res.data.subagentdata]);
            }
            else {
                toast.error("Check you connection");
            }
        })
    }

    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:SUB AGENT::DASHBOARD</title>
            </Helmet>
            <ContentWrapper>
                {(() => {
                    if (moment().format('a') === "pm") {
                        return (
                            <h4 className="dashboardname">Good Afternoon, <span>{subagent?.subagent_name}</span></h4>
                        )
                    }
                    else {
                        return (
                            <h4 className="dashboardname">Good Morning, <span>{subagent?.subagent_name}</span></h4>
                        )
                    }
                })()}

                {/* Leave Balance */}
                <div className="">
                    <div className="col-12 col-lg-12 col-xl-12">
                        <DashboardSection />
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12">
                        <DashboardOverview />
                    </div>
                    <div className="row row-cols-1 row-cols-md-6 row-cols-xl-12 productsection yearsection">
                        <div className="col-md-6">
                            <TopProductSals />
                        </div>
                        <div className="col-md-6">
                            <MostPopularLocation />
                        </div>
                    </div>
                </div>
                {/* End */}
            </ContentWrapper>
        </div>
    )
}

export default Dashboard
